import React from 'react';
import '../../App.css';

export const StudentStart = () => {
  return (
    <>
      <div className="uk-flex-top" id="modal-start" uk-modal="true">
        <div className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
          <div>
            <p className="uk-text-center">
              画面に質問が表示されます。<br />
              <br />
              当てはまることがいくつもあるときは、<br />
              先生に一番伝えたいものを選んでください。
            </p>
            <p className="uk-text-center">
              <button className="uk-button uk-button-default uk-modal-close" type="button">はじめる</button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
