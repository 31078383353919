import React, {useEffect, useState} from 'react';
import '../../App.css';
import {useIssuesState} from '../../hooks/useIssuesState';
import moment from 'moment';

export const StudentIssueList = () => {
  const {issues} = useIssuesState();
  const [selectedIssue, setSelectedIssue] = useState(null);

  const handleSelectIssue = (issue) => {
    setSelectedIssue(issue)
  }

  return (
    <>
      <div className="uk-modal-container uk-flex-top" id="modal-issuelist" uk-modal="true">
        <div className="uk-modal-dialog uk-margin-auto-vertical">
          <div className="uk-modal-header">
            <h3 className="uk-text-bold uk-text-center uk-width-1-1">あなたの<ruby><rb>相談</rb><rp>(</rp><rt>そうだん</rt><rp>)</rp></ruby>の<ruby><rb>記録</rb><rp>(</rp><rt>きろく</rt><rp>)</rp></ruby></h3>
          </div>
          <div className="uk-modal-body uk-height-xlarge">
            <div className="uk-width-1-1 uk-height-1-1 uk-margin-remove" uk-grid="true">
              <div className="uk-width-2-5 uk-height-1-1 uk-padding-remove">
                <div className="uk-panel uk-panel-scrollable uk-height-1-1">
                  <ul className="uk-list uk-list-divider">
                    {issues.data.map((issue, i) => (
                      <li id={i} key={`issue-li-${i}`}>
                        {moment(issue.createdAt).format('YYYY/MM/DD HH:mm') + ' ' + issue.overview}
                      </li>
                    ))}
                  </ul>     
                </div>                  
              </div>
            </div>
          </div>
          <button className="uk-modal-close-default" type="button" uk-close="true"></button>
        </div>
      </div>
    </>
  );
};
