import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {decodeJwt} from '../utils/jwt';
import {getCookie, setCookie, setCookieGrade} from '../utils/cookie';
import {useNavigate} from 'react-router-dom';
import { useSearchParams } from "react-router-dom";

const userStateDefault = {
  email: '',
  area: '',
  school: '',
  grade: '',
  group: '',
  ucl: ''
}

export const userState = atom({
  key: 'userState',
  default: userStateDefault,
});

export const useUserState = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState);

  const [searchParams] = useSearchParams();

  const setUserData = useCallback(
    () => {
      const id_token = searchParams.get('id_token')
      const access_token = searchParams.get('access_token')

      setCookie(id_token, access_token)
      setUserFromIdToken(id_token);

      return {
        id_token,
        access_token
      };
    },
    []
  );

  const setUserFromIdToken = useCallback(
    (idToken) => {
      const userData = decodeJwt(idToken);
      const email = userData['email']
      console.log(userData)

      const affiliation = userData['custom:Affiliation'].split('/')
      const school = affiliation[1]
      const tmpUcl = affiliation[2];
      let ucl = ''

      // 第一階層がブランクの場合、教育委員会
      if (affiliation.length === 2 && school === '') {
        ucl = 'eb'

      // 第二階層が教師の場合、教師
      } else if (tmpUcl === '教師') {
        ucl = 'teacher'
      } else {
        ucl = 'student'
      }

      setUser({
        id: userData['sub'],
        email: email,
        school: school,
        grade: '',
        group: '',
        ucl: ucl
      });
    },
    []
  );

  const isLoggedin= useCallback(
    () => {
      const idToken = getCookie('Authorization');
      const accessToken = getCookie('Accesstoken');

      if (!idToken || !accessToken) {
        return false
      }

      setUserFromIdToken(idToken);

      return true;
    },
    []
  );

  const getURI = () => {
    const port = window.location.port ? `:${window.location.port}` : ''
    return `${window.location.protocol}//${window.location.hostname}${port}`
  }

  const login = () => {
    window.location.replace(`${process.env.REACT_APP_COGNITO_URL}/oauth2/authorize?client_id=29kdoml35cbha7tn9oh3mohqjq&response_type=CODE&redirect_uri=${getURI()}/loggedin`)
  }

  const logout = (back_to_url = '/') => {
    setCookie('', '');
    setCookieGrade('');
    setUser(userStateDefault);
    navigate('/');
    window.location.replace(`${process.env.REACT_APP_COGNITO_URL}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${getURI() + back_to_url}&response_type=code`)
  };

  const postOauth2Token = useCallback(
    async (code) => {
      const submitBody = {
        grant_type: 'authorization_code',
        client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_AUTH_CALLBACK_URL,
        code: code,
      };

      const params = new URLSearchParams(submitBody);

      const url = process.env.REACT_APP_COGNITO_URL + '/oauth2/token';
      const method = 'POST';
      const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
      const body = params;

      const res = await fetch(url, {method, headers, body});
      const data = await res.json();

      if (!res.ok) {
        return false;
      }

      setCookie(data['id_token'], data['access_token'])
      setUserFromIdToken(data['id_token']);

      return data;
    },
    []
  );

  return {
    user: user,
    setUser: setUser,
    setUserFromIdToken,
    login,
    logout,
    setUserData,
    isLoggedin,
    postOauth2Token,
  };
};
