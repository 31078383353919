export const enums = {
  consultWhen: [
    '今日',
    '昨日',
    '3日以内',
    '1週間以内',
    '1か月以内',
    '3か月以内',
    '半年以内',
    '半年以上前',
  ],
  consultTarget: [
    '私のこと',
    '見た',
    '聞いた',
    '参加した',
  ],
  consultLocation: [
    '学校',
    '学校以外',
    '学校と学校以外',
  ],
  consultOverview: [
    '言葉の暴力',
    '殴る・蹴る',
    'ネットで悪口',
    '仲間外れ',
    '悲しんでいる',
    'ルール違反',
    'ものを壊した',
    'その他',
  ],
}
