import React, {useEffect} from 'react';
import '../../../App.css';
import {useUserState} from '../../../hooks/useUserState';
import {useStudentConsultFormState} from '../../../hooks/useStudentConsultFormState';
import {useNavigate, useLocation} from 'react-router-dom';
import {StudentNavigationBar} from '../../../components/navi/studentNavigationBar';
import {enums} from '../../../utils/enums';

const StudentConsultWhenPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {isLoggedin} = useUserState();
  const {studentConsultForm, setStudentConsultFormState} = useStudentConsultFormState();
  const currentPageNum = Number(location.pathname.replace('/student/consult/', ''));

  useEffect(() => {
    if (isLoggedin() === false) {
      navigate('/')
    }

    if (studentConsultForm.name === '') {
      navigate('/student/consult/1')
    }
  }, []);

  const selectWhen = (event) => {
    const selectedIndex = Number(event.currentTarget.id.replace('when_', ''));
    const selectedValue = enums.consultWhen[selectedIndex];

    setStudentConsultFormState({
      ...studentConsultForm,
      when: selectedValue
    });
  }

  const handleProgress = (event) => {
    if (event.target.checked) {
      setStudentConsultFormState({
        ...studentConsultForm,
        progress: '1',
      });
    } else {
      setStudentConsultFormState({
        ...studentConsultForm,
        progress: '0',
      });
    }
  }

  const handlePrev = (event) => {
    if (currentPageNum > 1) {
      navigate('/student/consult/' + String(currentPageNum - 1))
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: '',
      });
    } else {
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: '',
      });
      navigate('/student')
    }
  }

  const handleNext = (event) => {
    if (studentConsultForm.when === '') {
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: 'エラー: いつおきたか選択してください',
      });
      return;
    }
    setStudentConsultFormState({
      ...studentConsultForm,
      errorMessage: '',
    });
    navigate('/student/consult/' + String(currentPageNum + 1))
  }

  return (
    <div className="wrapper">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <StudentNavigationBar disabledToTop />
        <div className="uk-width-1-1 uk-position-top uk-margin-large-top uk-container uk-animation-fade">
          <h1 className="uk-text-center uk-margin-large-top uk-margin-large-bottom">いつおきましたか？</h1>
          <div className="uk-panel uk-width-expand uk-text-center">
            <form className="uk-width-3-4@l uk-form-stacked uk-align-center">
              <p className={studentConsultForm.errorMessage ? "uk-text-danger uk-margin-remove-top" : 'uk-hidden'}>{studentConsultForm.errorMessage}</p>
              <ul className="uk-child-width-1-4@l" uk-grid="true">
                {enums.consultWhen.map((when, i) => (
                  <li id={`when_${i}`} onClick={selectWhen} className="uk-width-1-4@l uk-margin-small-bottom uk-margin-small-top" key={`when-li-${i}`}>
                    <div className={`uk-card ${studentConsultForm.when === when ? 'uk-card-primary' : 'uk-card-default'} uk-card-small selectCard`}>
                      <div className="uk-card-body uk-padding-remove">
                        <div className="uk-width-expand">
                          <h4 className="uk-margin-top uk-margin-bottom uk-text-center">{when}</h4>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="uk-text-center uk-margin-large-top">
                <label><input id="progress" className="uk-checkbox" type="checkbox" onClick={handleProgress} defaultChecked={studentConsultForm.progress === '1'}/> 今も続いている場合はここをチェック</label>
              </div>   
            </form>      
          </div>
          <div className="uk-child-width-1-2" uk-grid="true">
            <div>
              <button className="uk-button uk-button-primary uk-width-small uk-margin-top uk-align-right" onClick={handlePrev}><span uk-icon="triangle-left"></span>もどる</button>
            </div>
            <div>
              <button className="uk-button uk-button-primary uk-width-small uk-margin-top uk-align-left" onClick={handleNext}>つぎへ<span uk-icon="triangle-right"></span></button>            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentConsultWhenPage;
