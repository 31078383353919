import React from 'react';
import '../../App.css';
import {useStudentConsultFormState} from '../../hooks/useStudentConsultFormState';

export const StudentConfirm = ({message, onClickOK = () => {}}) => {
  const {studentConsultForm} = useStudentConsultFormState();

  return (
    <>
      <div className="uk-flex-top" id="modal-confirm" uk-modal="true">
        <div className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
            <div>
              <p>
                {message}
              </p>
              <div className="uk-child-width-1-2 uk-margin-large-top uk-margin-bottom uk-text-center" uk-grid="true">
                <div>
                  <button id="modal-confirm-close" className="uk-button uk-button-default uk-modal-close" type="button">いいえ（続ける）</button>
                </div>
                <div>
                  <button onClick={onClickOK} className="uk-button uk-button-default uk-modal-close" type="button">はい（相談をやめる）</button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};
