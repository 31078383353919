import React, {useEffect} from 'react';
import '../App.css';
import {useUserState} from '../hooks/useUserState';
import {useNavigate, useSearchParams} from 'react-router-dom';

const LoggedinPage = () => {
  const navigate = useNavigate();
  const {user, postOauth2Token} = useUserState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get('code')
    postOauth2Token(code)
  }, []);

  useEffect(() => {
    if (user.ucl === '') {
      return;
    } else {
      navigate('/student/confirm')
    }
  }, [user]);

  return (
    <div className="wrapper background-image-display">
    </div>
  );
};

export default LoggedinPage;
