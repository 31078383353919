import React, {useEffect} from 'react';
import '../../../App.css';
import {useUserState} from '../../../hooks/useUserState';
import {useStudentConsultFormState} from '../../../hooks/useStudentConsultFormState';
import {useNavigate, useLocation} from 'react-router-dom';
import {StudentNavigationBar} from '../../../components/navi/studentNavigationBar';
import {enums} from '../../../utils/enums';
import overview1_0 from '../../../images/overview1_0.png';
import overview2_0 from '../../../images/overview2_0.png';
import overview3_0 from '../../../images/overview3_0.png';
import overview4_0 from '../../../images/overview4_0.png';
import overview5_0 from '../../../images/overview5_0.png';
import overview6_0 from '../../../images/overview6_0.png';
import overview7_0 from '../../../images/overview7_0.png';
import overview8_0 from '../../../images/overview8_0.png';
import overview1_1 from '../../../images/overview1_1.png';
import overview2_1 from '../../../images/overview2_1.png';
import overview3_1 from '../../../images/overview3_1.png';
import overview4_1 from '../../../images/overview4_1.png';
import overview5_1 from '../../../images/overview5_1.png';
import overview6_1 from '../../../images/overview6_1.png';
import overview7_1 from '../../../images/overview7_1.png';
import overview8_1 from '../../../images/overview8_1.png';

const disabledImage = [
  overview1_0,
  overview2_0,
  overview3_0,
  overview4_0,
  overview5_0,
  overview6_0,
  overview7_0,
  overview8_0,
];

const enabledImage = [
  overview1_1,
  overview2_1,
  overview3_1,
  overview4_1,
  overview5_1,
  overview6_1,
  overview7_1,
  overview8_1,
]

const StudentConsultOverviewPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {user, isLoggedin} = useUserState();
  const {studentConsultForm, setStudentConsultFormState} = useStudentConsultFormState();
  const currentPageNum = Number(location.pathname.replace('/student/consult/', ''));

  useEffect(() => {
    if (isLoggedin() === false) {
      navigate('/')
    }

    if (studentConsultForm.name === '') {
      navigate('/student/consult/1')
    }
  }, []);

  const handleSelectOverview = (event) => {
    const selectedIndex = Number(event.currentTarget.id.replace('overview_', ''));
    const selectedValue = enums.consultOverview[selectedIndex];

    setStudentConsultFormState({
      ...studentConsultForm,
      overview: selectedValue
    });
  }

  const handlePrev = (event) => {
    if (currentPageNum > 1) {
      navigate('/student/consult/' + String(currentPageNum - 1))
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: '',
      });
    } else {
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: '',
      });
      navigate('/student')
    }
  }

  const handleNext = (event) => {
    if (studentConsultForm.overview === '') {
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: 'エラー: 何がおきたか選択してください',
      });
      return;
    }
    setStudentConsultFormState({
      ...studentConsultForm,
      errorMessage: '',
    });
    navigate('/student/consult/' + String(currentPageNum + 1))
  }

  return (
    <div className="wrapper">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <StudentNavigationBar disabledToTop />
        <div className="uk-width-1-1 uk-position-top uk-margin-large-top uk-container uk-animation-fade">
          <h1 className="uk-text-center uk-margin-large-top uk-margin-large-bottom uk-margin-small-bottom@l">何がおきましたか？</h1>
          <div className="uk-panel uk-width-expand uk-text-center">
            <form className="uk-width-3-4@l uk-form-stacked uk-align-center">
              <p className={studentConsultForm.errorMessage ? "uk-text-danger uk-margin-remove-top" : 'uk-hidden'}>{studentConsultForm.errorMessage}</p>
              <ul className="uk-child-width-1-4@l" uk-grid="true">
                {enums.consultOverview.map((overview, i) => (
                  <li id={`overview_${i}`} onClick={handleSelectOverview} className="uk-width-1-4@l uk-margin-small-bottom uk-margin-small-top">
                    <div className={`uk-card ${studentConsultForm.overview === overview ? 'uk-card-primary' : 'uk-card-default'} uk-card-small selectCard`}>
                      <div className="uk-card-body uk-padding-remove uk-text-center">
                        <div className="uk-grid-collapse@l uk-flex-middle" uk-grid="true">
                          <div className="uk-width-1-3 uk-width-1-1@l uk-text-center">
                            <img className="selectImage" src={studentConsultForm.overview === overview ? enabledImage[i] : disabledImage[i]} alt="" />
                          </div>
                          <div className="uk-width-expand uk-margin-remove-top">
                            <h4 className="uk-margin-top uk-margin-bottom cardtext">{overview}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </form>
          </div>
          <div className="uk-child-width-1-2 uk-margin-large-top" uk-grid="true">
            <div>
              <button className="uk-button uk-button-primary uk-width-small uk-margin-top uk-align-right" onClick={handlePrev}><span uk-icon="triangle-left"></span>もどる</button>
            </div>
            <div>
              <button className="uk-button uk-button-primary uk-width-small uk-margin-top uk-align-left" onClick={handleNext}>つぎへ<span uk-icon="triangle-right"></span></button>            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentConsultOverviewPage;
