import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {getCookie} from '../utils/cookie';

const studentConsultFormStateDefault = {
  name: '',
  anonymous: '0',
  when: '',
  progress: '0',
  target: '',
  overview: '',
  location: '',
  opponentName: '',
  observerName: '',
  detail: '',
  errorMessage: '',
  submitError: ''
}

export const studentConsultFormState = atom({
  key: 'studentConsultFormState',
  default: studentConsultFormStateDefault,
});

export const useStudentConsultFormState = () => {
  const [studentConsultForm, setStudentConsultFormState] = useRecoilState(studentConsultFormState);

  const postStudentConsultForm = useCallback(
    async () => {
      const method = 'POST';
      const headers = {
        'Authorization': getCookie('Authorization'),
        'Accesstoken': getCookie('Accesstoken')
      };

      const submitBody = {
        created_name: studentConsultForm.name,
        grade: getCookie('grade'),
        group: getCookie('group'),
        anonymous: studentConsultForm.anonymous,
        when: studentConsultForm.when,
        progress: studentConsultForm.progress,
        target: studentConsultForm.target,
        overview: studentConsultForm.overview,
        opponent: studentConsultForm.opponentName,
        observer: studentConsultForm.observerName,
        detail: studentConsultForm.detail,
        location: studentConsultForm.location,
        status: '0',
      };
      const url = `${process.env.REACT_APP_API_URL}/api/issues`;
      const res = await fetch(url, {method, headers, body: JSON.stringify(submitBody)})
      const data = await res.json();
      if (!res.ok) {
        return false;
      }

      return data;
    },
    [studentConsultForm]
  );

  const clearStudentConsultForm = useCallback(
    () => {
      setStudentConsultFormState(studentConsultFormStateDefault);
    },
    [studentConsultForm]
  );

  return {
    studentConsultForm: studentConsultForm,
    setStudentConsultFormState: setStudentConsultFormState,
    postStudentConsultForm,
    clearStudentConsultForm,
  };
};
