import React, {useEffect} from 'react';
import '../../../App.css';
import {useUserState} from '../../../hooks/useUserState';
import {useStudentConsultFormState} from '../../../hooks/useStudentConsultFormState';
import {useNavigate, useLocation} from 'react-router-dom';
import {StudentStart} from '../../../components/modal/studentStart';
import {StudentNavigationBar} from '../../../components/navi/studentNavigationBar';
import {getCookie} from '../../../utils/cookie';

const StudentConsultNamePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {user, isLoggedin} = useUserState();
  const {studentConsultForm, setStudentConsultFormState} = useStudentConsultFormState();
  const currentPageNum = Number(location.pathname.replace('/student/consult/', ''));

  useEffect(() => {
    if (isLoggedin() === false) {
      navigate('/')
    }

    if (studentConsultForm.name === '') {
      document.getElementById('modalStart').click();
    }
  }, []);

  const handleChange = (event) => {
    setStudentConsultFormState({
      ...studentConsultForm,
      [event.target.name]: event.target.value
    });
  }

  const handleAnonymous = (event) => {
    if (event.target.checked) {
      setStudentConsultFormState({
        ...studentConsultForm,
        anonymous: '1',
        name: '匿名'
      });
    } else {
      setStudentConsultFormState({
        ...studentConsultForm,
        anonymous: '0',
        name: ''
      });
    }
  }

  const handlePrev = (event) => {
    if (currentPageNum > 1) {
      navigate('/student/consult/' + String(currentPageNum - 1))
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: '',
      });
    } else {
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: '',
      });
      navigate('/student')
    }
  }

  const handleNext = (event) => {
    if (studentConsultForm.name === '') {
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: 'エラー: 名前を入力してください',
      });
      return;
    }
    setStudentConsultFormState({
      ...studentConsultForm,
      errorMessage: '',
    });

    navigate('/student/consult/' + String(currentPageNum + 1))
  }

  return (
    <div className="wrapper">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <StudentNavigationBar disabledToTop />
        <div className="uk-width-1-2@l uk-container uk-animation-fade">
          <h1 className="uk-text-center uk-margin-large-top uk-margin-large-bottom">名前を教えてください</h1>
          <div className="uk-panel uk-width-expand">
            <form className="uk-width-1-1 uk-form-horizontal">
              <div className="uk-margin-bottom">
                <label className="uk-form-label">あなたの名前</label>
                <div className="uk-form-controls">
                  {user.school} {getCookie('grade') ? getCookie('grade') + '年' : ''}  {getCookie('group') ? getCookie('group') + '組' : ''}
                  <input className="uk-input uk-width-1-1" id="created_name" type="text" placeholder="名前を入力してください" value={studentConsultForm.name} name="name" onChange={handleChange} disabled={studentConsultForm.anonymous === '1'} />
                  <p className={studentConsultForm.errorMessage ? "uk-text-danger uk-margin-remove-top" : 'uk-hidden'}>{studentConsultForm.errorMessage}</p>
                </div>
              </div>
              <div className="uk-margin-bottom">
                <label className="uk-form-label uk-margin-remove-top">名前を言わずに相談する</label>
                <div className="uk-form-controls">
                  <input id="anonymous" className="uk-checkbox" type="checkbox" onClick={handleAnonymous} defaultChecked={studentConsultForm.anonymous === '1'}/>
                </div>
              </div>
              <div className="uk-margin-large-top uk-margin-bottom">
                <p className="uk-text-meta">
                  「名前を言わずに相談する」をチェックした場合、あなたの名前を書く必要はありません。あなたの事を助けたいと思っているので、名前を教えてくれると嬉しいです。
                </p>
              </div>
            </form>
          </div>
          <div className="uk-child-width-1-2 uk-margin-large-top" uk-grid="true">
            <div>
              <button className="uk-button uk-button-primary uk-width-small uk-margin-top uk-align-right " onClick={handlePrev}><span uk-icon="triangle-left"></span>もどる</button>
            </div>
            <div>
              <button className="uk-button uk-button-primary uk-width-small uk-margin-top uk-align-left" onClick={handleNext}>つぎへ<span uk-icon="triangle-right"></span></button>            
            </div>
          </div>
          <button id="modalStart" className="uk-hidden" uk-toggle="target: #modal-start" />
          <StudentStart />
        </div>
      </div>
    </div>
  );
};

export default StudentConsultNamePage;
