import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login';
import LoggedinPage from './pages/loggedin';
import StudentTopPage from './pages/student/index';
import StudentConfirmPage from './pages/student/confirm';
import StudentConsultNamePage from './pages/student/consult/name';
import StudentConsultWhenPage from './pages/student/consult/when';
import StudentConsultLocationPage from './pages/student/consult/location';
import StudentConsultTargetPage from './pages/student/consult/target';
import StudentConsultOverviewPage from './pages/student/consult/overview';
import StudentConsultDetalPage from './pages/student/consult/detail';
import StudentConsultSummaryPage from './pages/student/consult/summary';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* TOP */}
        <Route path="/" exact element={<LoginPage />} />
        <Route path="/loggedin" exact element={<LoggedinPage />} />
        {/* student */}
        <Route path="/student/" exact element={<StudentTopPage />} />
        <Route path="/student/confirm" exact element={<StudentConfirmPage />} />
        <Route path="/student/consult/1" exact element={<StudentConsultNamePage />} />
        <Route path="/student/consult/2" exact element={<StudentConsultWhenPage />} />
        <Route path="/student/consult/3" exact element={<StudentConsultLocationPage />} />
        <Route path="/student/consult/4" exact element={<StudentConsultTargetPage />} />
        <Route path="/student/consult/5" exact element={<StudentConsultOverviewPage />} />
        <Route path="/student/consult/6" exact element={<StudentConsultDetalPage />} />
        <Route path="/student/consult/7" exact element={<StudentConsultSummaryPage />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
