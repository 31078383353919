import React from 'react';
import '../App.css';
import toptitle from '../images/toptitle.png';
import {useUserState} from '../hooks/useUserState';

const LoginPage = () => {
  const {login} = useUserState();

  const handleLogin = async () => {
    login()
  }

  const handleOpenDocument = (event) => {
    window.open(process.env.REACT_APP_URL + '/kiyaku.html', 'kiyaku');
  }

  return (
    <div className="wrapper background-image-display">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <div className='uk-container uk-animation-fade'>
          <img className='uk-align-center uk-width-small' src={toptitle} alt="title" width="300" />
          <button className="uk-button uk-button-primary uk-width-medium uk-align-center" type="button" onClick={handleLogin}>ログイン</button> 
          <button className="uk-button uk-hidden" id="modal-error" type="button" uk-toggle="target: #modal-error"></button>
          <hr />
          <button id="kiyaku" className="uk-button uk-button-text uk-margin-top uk-align-center" type="button" onClick={handleOpenDocument}>マモレポ利用規約</button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
