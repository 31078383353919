import React, {useEffect, useState} from 'react';
import '../../App.css';
import {useNavigate} from 'react-router-dom';
import {StudentNavigationBar} from '../../components/navi/studentNavigationBar';
import {useUserState} from '../../hooks/useUserState';
import {setCookieGrade, setCookieGroup} from '../../utils/cookie';

const StudentConfirmPage = () => {
  const navigate = useNavigate();
  const {user, setUser, isLoggedin} = useUserState();
  const [grade, setGrade] = useState('');
  const [group, setGroup] = useState('');
  const imageNumber = Math.floor(Math.random() * 9) + 1

  useEffect(() => {
    if (isLoggedin() === false) {
      navigate('/')
    }
  }, []);

  const handleConfirm = () => {
    if (grade === '') {
      alert('学年を選択してください');
      return;
    }
    setUser({...user, grade: grade, group: group})
    setCookieGrade(grade)
    setCookieGroup(group)
    navigate('/student')
  }

  const handleSelectGrade = (e) => {
    setGrade(e.target.value)
  }

  const handleSelectGroup = (e) => {
    setGroup(e.target.value)
  }

  return (
    <div className="wrapper">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <StudentNavigationBar />
        <div className="uk-width-1-1 uk-margin uk-align-center uk-margin-large-top">
          <div className="uk-text-center uk-align-center">
            <div className="uk-text-center" style={{fontSize: "24px", fontWeight: "bold", marginBottom: "30px"}}>もし　いじめなどで　困ったことが<br />あったらマモレポを　つかって<br />相談することができるよ</div>
            <div className="uk-text-center uk-margin-bottom" style={{fontSize: "18px", fontWeight: "bold", color: "orange"}}>先生がマモレポを確認できるのは、平日の8時から16時30分のみです。<br />確認に時間がかかるから急ぎの場合は、<br />先生に直接伝えてね</div>
            あなたの学校、学年を教えてください。
            <br />
            <div>
            学校: {user.school}
            </div>
            <br />
            学年:
            <select id="searchStudentClosed" className="uk-select uk-width-medium" name="searchStatus" onChange={handleSelectGrade}>
              <option value="">学年を選んでください</option>
              {user.school.includes('中学校') ? (
                <>
                  <option value="1">1年</option>
                  <option value="2">2年</option>
                  <option value="3">3年</option>
                </>
              ) : (
                <>
                  <option value="4">4年</option>
                  <option value="5">5年</option>
                  <option value="6">6年</option>
                </>
              )}
            </select>
            <br />
            クラス:
            <select id="searchStudentClosed" className="uk-select uk-width-medium" name="searchStatus" onChange={handleSelectGroup}>
              <option value="">クラスを選んでください</option>
              <option value="1">1組</option>
              <option value="2">2組</option>
              <option value="3">3組</option>
              <option value="4">4組</option>
              <option value="">回答なし</option>
            </select>
          </div>

          <div className="uk-width-1-1 uk-margin-top uk-margin-bottom uk-align-center" uk-grid="true">
              <button className="uk-button uk-button-primary uk-margin-top uk-align-center" type="button" onClick={handleConfirm}>次へ進む</button>
          </div>
        </div>
 
      </div>
    </div>
  );
};

export default StudentConfirmPage;
