import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {getCookie} from '../utils/cookie';

export const issuesState = atom({
  key: 'issuesState',
  default: {
    currentPage: '1',
    searchSchool: '',
    searchStatus: '',
    data: []
  },
});

export const useIssuesState = () => {
  const [issues, setIssues] = useRecoilState(issuesState);

  const fetchIssues = useCallback(
    async (uid = null, setState = true) => {
      const url = process.env.REACT_APP_API_URL + '/api/issues';
      const method = 'GET';
      const headers = {
        'Authorization': getCookie('Authorization'),
        'Accesstoken': getCookie('Accesstoken')
      };

      const res = await fetch(url, {method, headers});
      if (!res.ok) {
        return false;
      }

      const data = await res.json();

      if (setState) {
        const sortedIssues = data['issues'].sort((a,b) => {
          if(a.createdAt < b.createdAt){
            return 1;
          }else{
            return -1;
          }
        });
        setIssues({data: sortedIssues})
      }

      return data;
    },
    []
  );

  return {
    issues: issues,
    setIssues: setIssues,
    fetchIssues,
  };
};
