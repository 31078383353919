import React, {useEffect} from 'react';
import '../../../App.css';
import {useUserState} from '../../../hooks/useUserState';
import {useStudentConsultFormState} from '../../../hooks/useStudentConsultFormState';
import {useNavigate, useLocation} from 'react-router-dom';
import {StudentNavigationBar} from '../../../components/navi/studentNavigationBar';
import {enums} from '../../../utils/enums';
import location1_0 from '../../../images/location1_0.png';
import location2_0 from '../../../images/location2_0.png';
import location3_0 from '../../../images/location3_0.png';
import location1_1 from '../../../images/location1_1.png';
import location2_1 from '../../../images/location2_1.png';
import location3_1 from '../../../images/location3_1.png';

const disabledImage = [
  location1_0,
  location2_0,
  location3_0
];

const enabledImage = [
  location1_1,
  location2_1,
  location3_1
]

const StudentConsultLocationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {isLoggedin} = useUserState();
  const {studentConsultForm, setStudentConsultFormState} = useStudentConsultFormState();
  const currentPageNum = Number(location.pathname.replace('/student/consult/', ''));

  useEffect(() => {
    if (isLoggedin() === false) {
      navigate('/')
    }

    if (studentConsultForm.name === '') {
      navigate('/student/consult/1')
    }
  }, []);

  const handleSelectLocation = (event) => {
    const selectedIndex = Number(event.currentTarget.id.replace('location_', ''));
    const selectedValue = enums.consultLocation[selectedIndex];

    setStudentConsultFormState({
      ...studentConsultForm,
      location: selectedValue
    });
  }

  const handlePrev = (event) => {
    if (currentPageNum > 1) {
      navigate('/student/consult/' + String(currentPageNum - 1))
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: '',
      });
    } else {
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: '',
      });
      navigate('/student')
    }
  }

  const handleNext = (event) => {
    if (studentConsultForm.location === '') {
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: 'エラー: 場所を選択してください',
      });
      return;
    }
    setStudentConsultFormState({
      ...studentConsultForm,
      errorMessage: '',
    });
    navigate('/student/consult/' + String(currentPageNum + 1))
  }

  return (
    <div className="wrapper">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <StudentNavigationBar disabledToTop />
        <div className="uk-width-1-1 uk-position-top uk-margin-large-top uk-container uk-animation-fade">
          <h1 className="uk-text-center uk-margin-large-top uk-margin-large-bottom">どこで起きましたか？</h1>
          <div className="uk-panel uk-width-expand uk-text-center">
            <form className="uk-width-3-4@l uk-form-stacked uk-align-center">
              <p className={studentConsultForm.errorMessage ? "uk-text-danger uk-margin-remove-top" : 'uk-hidden'}>{studentConsultForm.errorMessage}</p>
              <ul className="uk-child-width-1-3@l" uk-grid="true">
                {enums.consultLocation.map((location, i) => (
                  <li id={`location_${i}`} onClick={handleSelectLocation} className="uk-width-1-3@l uk-margin-small-bottom uk-margin-small-top">
                    <div className={`uk-card ${studentConsultForm.location === location ? 'uk-card-primary' : 'uk-card-default'} uk-card-small selectCard`}>
                      <div className="uk-card-body uk-padding-remove uk-text-center">
                        <div className="uk-grid-collapse@l uk-flex-middle" uk-grid="true">
                          <div className="uk-width-1-3 uk-width-1-1@l uk-text-center">
                            <img className="selectImage" src={studentConsultForm.location === location ? enabledImage[i] : disabledImage[i]} />
                          </div>
                          <div className="uk-width-expand uk-margin-remove-top">
                            <h4 className="uk-margin-top uk-margin-bottom cardtext">{location}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </form>
          </div>
          <div className="uk-child-width-1-2 uk-margin-large-top" uk-grid="true">
            <div>
              <button className="uk-button uk-button-primary uk-width-small uk-margin-top uk-align-right" onClick={handlePrev}><span uk-icon="triangle-left"></span>もどる</button>
            </div>
            <div>
              <button className="uk-button uk-button-primary uk-width-small uk-margin-top uk-align-left" onClick={handleNext}>つぎへ<span uk-icon="triangle-right"></span></button>            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentConsultLocationPage;
