import React, {useEffect} from 'react';
import '../../App.css';
import {StudentNavigationBar} from '../../components/navi/studentNavigationBar';
import {useUserState} from '../../hooks/useUserState';
import {useNavigate} from 'react-router-dom';
import {StudentIssueList} from '../../components/modal/studentIssueList';
import {useIssuesState} from '../../hooks/useIssuesState';

import mamorepo01 from '../../images/mamorepo01.jpg';
import mamorepo02 from '../../images/mamorepo02.jpg';

const StudentTopPage = () => {
  const navigate = useNavigate();
  const {isLoggedin} = useUserState();
  const {fetchIssues} = useIssuesState();

  useEffect(() => {
    if (isLoggedin() === false) {
      navigate('/')
    }
  }, []);

  const handleIssueListOpen = () => {
    fetchIssues();
  }

  const handleStartConsult = () => {
    navigate('/student/consult/1')
  }

  return (
    <div className="wrapper">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <StudentNavigationBar />
        <div className="uk-width-1-1 uk-position-top uk-container uk-animation-fade">
          <div className="uk-width-1-1 uk-margin-large-top" uk-grid="true">
            <div className="uk-width-2-3">
              <div className="uk-child-width-1-1 uk-margin-remove-top uk-margin-small-bottom" uk-grid="true">
                <div className="uk-margin-bottom">
                  <img className="uk-margin" width="100%" src={mamorepo01} alt="" />
                  <img className="uk-margin" width="100%" src={mamorepo02} alt="" />
                </div>
              </div>
            </div>
            <div className="uk-width-1-3">
              <h3>　</h3>
              <button className="uk-button uk-button-primary uk-width-large uk-margin-remove-top uk-padding-remove-left uk-padding-remove-right uk-align-center" onClick={handleStartConsult}>
                <div  className="uk-flex-middle uk-padding-small" uk-grid="true">
                  <div className="uk-width-1-5">
                    <span uk-icon="icon: mail; ratio: 2"></span>
                  </div>
                  <div className="uk-width-expand uk-padding-remove uk-margin-small-left">
                    <p className="uk-align-left uk-text-large uk-margin-remove-right">相談をする</p>
                  </div>
                </div>
              </button>
              <button className="uk-button uk-button-primary uk-width-large uk-padding-remove-left uk-padding-remove-right uk-align-center" onClick={handleIssueListOpen} uk-toggle="target: #modal-issuelist">
                <div  className="uk-flex-middle uk-padding-small" uk-grid="true">
                  <div className="uk-width-1-5">
                    <span uk-icon="icon: list; ratio: 2"></span>
                  </div>
                  <div className="uk-width-expand uk-padding-remove uk-margin-small-left">
                    <p className="uk-align-left uk-text-large uk-margin-remove-right">相談の記録を見る</p>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <StudentIssueList />
    </div>
  );
};

export default StudentTopPage;
