import React, {useEffect, useState} from 'react';
import '../../../App.css';
import {useUserState} from '../../../hooks/useUserState';
import {useStudentConsultFormState} from '../../../hooks/useStudentConsultFormState';
import {useNavigate} from 'react-router-dom';
import {StudentNavigationBar} from '../../../components/navi/studentNavigationBar';
import {StudentConsultComplete} from '../../../components/modal/studentConsultComplete';
import {StudentConfirm} from '../../../components/modal/studentConfirm';
import {enums} from '../../../utils/enums';

const StudentConsultSummaryPage = () => {
  const navigate = useNavigate();
  const {user, isLoggedin} = useUserState();
  const {studentConsultForm, setStudentConsultFormState, postStudentConsultForm, clearStudentConsultForm} = useStudentConsultFormState();
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  useEffect(() => {
    if (isLoggedin() === false) {
      navigate('/')
    }

    if (studentConsultForm.name === '') {
      navigate('/student/consult/1')
    }
  }, []);

  const handleChange = (event) => {
    setStudentConsultFormState({
      ...studentConsultForm,
      [event.target.name]: event.target.value
    });
  }

  const handleStopConsult = (event) => {
    window.gtag('event', 'StopConsult', {});
    clearStudentConsultForm();
    navigate('/student/');
  }

  const handleSubmit = async (event) => {
    if (studentConsultForm.name === '') {
      return;
    }

    setDisabledSubmit(true);

    window.gtag('event', 'Submit', {});
    const res = await postStudentConsultForm();
    if (res === false) {
      setStudentConsultFormState({
        ...studentConsultForm,
        submitError: '1'
      });

      setDisabledSubmit(false);
      return;
    }

    clearStudentConsultForm();

    navigate('/student/')
  }

  const handleCheckAnonymous = (event) => {
    if (event.target.checked) {
      setStudentConsultFormState({
        ...studentConsultForm,
        anonymous: '1',
        name: '匿名'
      });
    } else {
      setStudentConsultFormState({
        ...studentConsultForm,
        anonymous: '0',
        name: ''
      });
    }
  }

  const handleChangeCheck = (event) => {
    if (event.target.checked) {
      setStudentConsultFormState({
        ...studentConsultForm,
        [event.target.name]: '1',
      });
    } else {
      setStudentConsultFormState({
        ...studentConsultForm,
        [event.target.name]: '0',
      });
    }
  }

  return (
    <div className="wrapper">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <StudentNavigationBar disabledToTop />
        <div className="uk-width-1-2@l uk-position-top uk-margin-large-top uk-container uk-animation-fade">
          <h1 className="uk-text-center uk-margin-large-top">相談内容の確認</h1>
          <h4 className="uk-text-center uk-margin-small-top uk-margin-large-bottom">変更もできます</h4>
          <form className="uk-width-1-1 uk-form-horizontal">
            <div className="uk-margin-bottom">
              <label className="uk-form-label">あなたの名前</label>
              <div className="uk-form-controls">
                <input className="uk-input uk-width-1-1" id="createdNamecf" type="text" placeholder="あなたの名前" name="name" value={studentConsultForm.name}  onChange={handleChange} disabled={studentConsultForm.anonymous === '1'} />
                <p className={disabledSubmit === false && studentConsultForm.name === "" ? "uk-text-danger uk-margin-remove-top" : 'uk-hidden'}>エラー: 名前を入力してください</p>
              </div>
            </div>
            <div className="uk-margin-bottom">
              <label className="uk-form-label uk-margin-remove-top">名前を言わずに相談する</label>
              <div className="uk-form-controls">
                <input id="anonymouscf" className="uk-checkbox" type="checkbox" name="anonymous" value={studentConsultForm.anonymous} checked={studentConsultForm.anonymous === '1'} onChange={handleCheckAnonymous}/>
              </div>
            </div>
            <div className="uk-margin-bottom">
              <label className="uk-form-label">いつおきましたか？</label>
              <div className="uk-form-controls">
                <select id="whencf" className="uk-select" name="when" value={studentConsultForm.when} onChange={handleChange}>
                  {enums.consultWhen.map((when) => (
                    <option value={when}>{when}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="uk-margin-bottom">
              <label className="uk-form-label uk-margin-remove-top">今も続いている</label>
              <div className="uk-form-controls">
                <input id="progresscf" className="uk-checkbox" type="checkbox" name="progress" value={studentConsultForm.progress} checked={studentConsultForm.progress === '1'} onChange={handleChangeCheck} />
              </div>
            </div>
            <div className="uk-margin-bottom">
              <label className="uk-form-label">どこで起きましたか？</label>
              <div className="uk-form-controls">
                <select id="targetcf" className="uk-select" name="location" value={studentConsultForm.location} onChange={handleChange}>
                  {enums.consultLocation.map((target) => (
                    <option value={target}>{target}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="uk-margin-bottom">
              <label className="uk-form-label">誰のことですか？<br />(どうしましたか？)</label>
              <div className="uk-form-controls">
                <select id="targetcf" className="uk-select" name="target" value={studentConsultForm.target} onChange={handleChange}>
                  {enums.consultTarget.map((target) => (
                    <option value={target}>{target}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="uk-margin-bottom" style={{marginTop: "40px"}}>
              <label className="uk-form-label">何がおきましたか？</label>
              <div className="uk-form-controls">
                <select id="overviewcf" className="uk-select" name="overview" value={studentConsultForm.overview} onChange={handleChange}>
                  {enums.consultOverview.map((overview) => (
                    <option value={overview}>{overview}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="uk-margin-bottom">
              <label className="uk-form-label">相手は誰ですか？</label>
              <div className="uk-form-controls">
                <input className="uk-input uk-width-1-1" id="opponent_namecf" type="text" placeholder="何人でも書けます" name="opponentName" value={studentConsultForm.opponentName} onChange={handleChange}/>
              </div>
            </div>
            <div className="uk-margin-bottom">
              <div className="uk-form-label">知っている人はいますか？</div>
              <div className="uk-form-controls">
                <input className="uk-input" id="observer_namecf" type="text" placeholder="何人でも書けます" name="observerName" value={studentConsultForm.observerName} onChange={handleChange} />
              </div>
            </div>
            <div>
              <div className="uk-form-label">詳しい内容</div>
              <div className="uk-form-controls">
                <textarea id="detailcf" className="uk-textarea textarea_fixed" rows="5" maxLength="1000" placeholder="1000文字まで書けます"　name="detail" value={studentConsultForm.detail} onChange={handleChange} ></textarea>
              </div>
            </div>
            <div class="uk-margin-top">「いたずら」や「なりすまし」は、法律で罰せられる可能性があり調査する場合もあります。</div>
          </form>
          <div className="uk-child-width-1-2 uk-margin-large-top uk-margin-bottom" uk-grid="true">
            <div>
              <button className="uk-button uk-button-default uk-margin-top uk-align-right" uk-toggle="target: #modal-confirm">相談をやめる</button>
              <button className="uk-button uk-hidden" id="modal-error" type="button" uk-toggle="target: #modal-error"></button>
            </div>
            <div>
              <button id="submitIssue" className="uk-button uk-button-danger uk-width-small uk-margin-top uk-align-left" onClick={handleSubmit} disabled={disabledSubmit} uk-toggle="target: #modal-submit">送信する</button>
              <button className="uk-button uk-hidden" id="modal-error" type="button" uk-toggle="target: #modal-error"></button>            
            </div>
          </div>
        </div>
        <StudentConsultComplete />
        <StudentConfirm message={"本当に相談をやめますか？"} onClickOK={handleStopConsult}/>
      </div>
    </div>
  );
};

export default StudentConsultSummaryPage;
